.form-container {
  width: 400px;
  margin: 100px auto;
  padding: 25px 40px 25px 40px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.form-container h2 {
  margin-bottom: 50px;
}

.input-lable-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.label {
  display: flex;
  justify-content: flex-start;
}

.label label {
  color: black;
}

.input {
  display: flex;
  justify-content: flex-start;
}

.input input {
  border: none;
  border-bottom: 1px dotted #d4af37ff;
  width: 200px;
  outline: none;
  font-size: 16px;
}

.textarea-lable-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.textarea {
  display: flex;
  margin-top: 20px;
}

.textarea textarea {
  border: 1px dotted #d4af37ff;
  width: 100%;
  border-radius: 20px;
  padding: 15px;
  height: 50px;
  resize: none;
}

.submit-button {
  padding: 10px 15px;
  font-size: 16px;
  color: "#fff";
  background-color: "#007bff";
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: green;
  color: white;
}

.success-message {
  color: green;
  margin-bottom: 30px;
}
