.footer {
  height: 100px;
  background-color: white;
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-top: 126px;
}

.footer p {
  margin-bottom: 40px;
}

.contact-details {
  font-size: 25px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

span {
  color: darkorange;
}

.contact {
  margin-left: 15px;
}

a {
  color: gray;
}

a:hover {
  color: yellow;
  transition: all 0.5s;
}

.eotf-website {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .footer {
    position: relative;
  }
}
