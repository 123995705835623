.header-container {
  display: flex;
  justify-content: space-between;
  background-color: black;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.header-container .empty {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
}

.header-container .empty img {
  width: 130px;
  height: 130px;
  margin-left: 50px;
}

.header-container .company-logo {
  flex: 3;
}

.header-container .navbar {
  flex: 2;
}

.header-container .gold-atom img {
  width: 120px;
}

@media screen and (max-width: 600px) {
  .header-container {
    flex-direction: column;
  }

  .header-container .empty {
    display: none;
  }

  .header-container .company-logo {
    margin: 10px auto;
    margin-left: 15px;
  }

  .header-container .company-logo img {
    width: 350px;
  }

  .header-container .navbar .navbar-container {
    display: flex;
    flex-direction: row;
  }
}
