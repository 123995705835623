.navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px;
}

.navbar-container .navlink {
  text-decoration: none;
  border-radius: 20px;
  padding: 8px 4px 8px 4px;
  margin-bottom: 6px;
  min-width: fit-content;
  color: #d4af37ff;
  margin-bottom: 10px;
}

.navbar-container .navlink .button {
  width: 65px;
  border: none;
}

.navbar-container .navlink .button .icon {
  font-size: 15px;
}

.navbar-container .navlink:hover {
  background-color: #d4af37ff;
  color: black;
}
