.courses-container {
  margin-top: 150px;
}

.course-imgs-container {
  border-radius: 2px dashed green;
  width: 60%;
  margin: 50px auto;
  border-top: none;
  border-bottom: none;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.academy-title {
  color: green;
}

.academy-title span {
  color: darkorange;
}

.course-imgs-container .course-img-container {
  margin-top: 50px;
}

.course-imgs-container .course-img-container h3 {
  text-decoration: underline;
  color: green;
}

@media (max-width: 600px) {
  .course-imgs-container {
    border: none;
    width: 98%;
    margin-top: 100px;
  }

  .course-imgs-container .course-img-container img {
    max-width: 100%;
  }

  .eotfa-logo img {
    width: 200px !important;
  }
}
