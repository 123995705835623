.home-page {
  min-height: 950px;
}

/* Title Home Page */

.home-page .title-home-page {
  display: flex;
  justify-content: space-between;
  height: 230px;
}

.home-page .title-home-page .quality-text {
  flex: 1;
}

.home-page .title-home-page .quality-text img {
  max-width: 160px;
}

.home-page .title-home-page .title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 10;
  margin-top: 40px;
}

.home-page .title-home-page .title img {
  max-width: 600px;
}

.home-page .title-home-page .navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  padding-right: 15px;
}

.home-page .title-home-page .navbar .line {
  border-left: 2px dashed rgb(193, 192, 192);
  width: 0.5px;
  height: 25px;
  align-self: center;
}

.home-page .title-home-page .navbar .navlink {
  border: 1px rgb(141, 138, 138) solid;
  text-decoration: none;
  border-radius: 20px;
  padding: 8px 4px 8px 4px;
  margin-bottom: 6px;
  min-width: fit-content;
  color: rgb(141, 138, 138);
}

.home-page .title-home-page .navbar .navlink:hover {
  background-color: #d4af37ff;
  border: 1px #d4af37ff solid;
  color: black;
}

/* Content Home Page */
.home-page .content-home-page {
  display: flex;
  justify-content: space-between;
  height: 230px;
}

.home-page .content-home-page .gold-atom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page .content-home-page .gold-atom img {
  max-width: 150px;
}

.home-page .content-home-page .gold-atom {
  flex: 1;
  border: 10px white solid;
}

.home-page .content-home-page .text-by-amer {
  display: flex;
  justify-content: center;
  align-items: self-start;
  margin-top: -40px;
  flex: 10;
}

.home-page .content-home-page .text-by-amer img {
  width: 600px;
}

.home-page .content-home-page .empty-area {
  flex: 1;
  border: 10px white solid;
}

/* Banner Home Page */

.home-page .banner-home-page {
  display: flex;
  height: 230px;
}

.home-page .banner-home-page .photo {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  background-color: black;
}

.home-page .banner-home-page .photo img {
  width: 540px;
}

.home-page .banner-home-page .company-logo {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  flex: 1;
  background-color: black;
}

@media screen and (max-width: 600px) {
  .home-page {
    min-height: 1200px;
  }

  .home-page .title-home-page .quality-text img {
    border: 2px solid #d4af37ff;
    margin-top: 8px;
  }

  .home-page .title-home-page {
    display: flex;
    width: 400px;
    height: 300px;
  }

  .home-page .title-home-page .title {
    display: none;
  }

  .home-page .title-home-page .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
  }

  .home-page .title-home-page .navbar .line {
    display: none;
  }

  .home-page .title-home-page .navbar .navlink {
    width: 50%;
    border: 1.75px solid #d4af37ff;
  }

  .home-page .content-home-page .gold-atom {
    display: none;
  }

  .home-page .content-home-page .empty-area {
    display: none;
  }

  .home-page .content-home-page .text-by-amer img {
    width: 90%;
    margin-top: 50px;
  }

  .home-page .banner-home-page {
    flex-direction: column;
  }

  .home-page .banner-home-page .photo {
    order: 2;
    display: flex;
    justify-content: center;
  }

  .home-page .banner-home-page .company-logo {
    order: 1;
  }

  .home-page .banner-home-page .company-logo img {
    margin-bottom: 20px;
  }

  .home-page .banner-home-page img {
    max-width: 90%;
    align-items: center;
  }

  .footer {
    margin-top: 300px;
  }
}
